import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function useRedirectToErrorPage(errorMessage) {
    const navigate = useNavigate();

    return (useEffect(() => {
        if (errorMessage) {
            navigate(
                "/error",
                {
                    state: {
                        errorMessage: errorMessage
                    }
                })
        }
    }, [navigate, errorMessage]))

}
