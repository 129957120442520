import React from 'react'
import { Link } from "react-router-dom";
import { GeneralButton } from '../components/GeneralButton';
import { useOutletContext } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useRedirectToErrorPage from './errors/hooks/redirectToErrorPage';
import axios from 'axios';


export const Introduction = () => {
  const clientCode = useOutletContext();
  const [errorMessage, setErrorMessage] = useState();
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [contractUrl, setContractUrl] = useState("");
  // eslint-disable-next-line
  const redirectToErrorPage = useRedirectToErrorPage(errorMessage);

  useEffect(() => {
    fetchClient();
  }, [clientCode])

  useEffect(() => {
    fetchContract()
  }, [clientEmail])

  function fetchContract() {
    if(clientEmail === "") {
      return;
    }
    let body = JSON.stringify(
      {
        "key": process.env.REACT_APP_API_KEY,
        "dati": 10,
        "email": clientEmail
      }
    )
    if (clientCode !== '' && clientCode !== null) {
      axios.post(`${process.env.REACT_APP_API_BASE_URL}/contratto_digitale/data.php`, body).then((response) => {
        if (response.data["status"] !== "success") {
          setErrorMessage(`Si è verificato un errore nell'estrazione del contratto. Rieffettuare la procedura con un nuovo link. Se l'errore persiste contattare l'assistenza. ${response.data["descrizione"]}`)
        } else {
          setContractUrl(response.data["data"]["link"]);
        }
      })
    }
  }

  function fetchClient() {
    let body = JSON.stringify(
      {
        "key": process.env.REACT_APP_API_KEY,
        "dati": 2,
        "codice_utente": clientCode
      }
    )
    if (clientCode !== '' && clientCode !== null) {
      axios.post(`${process.env.REACT_APP_API_BASE_URL}/contratto_digitale/data.php`, body).then((response) => {
        if (response.data["status"] !== "success") {
          setErrorMessage(`Si è verificato un errore. Rieffettuare la procedura con un nuovo link. Se l'errore persiste contattare l'assistenza. ${response.data["descrizione"]}`)
        } else {
          setClientName(`${response.data["data"]["nome"]} ${response.data["data"]["cognome"]}`);
          setClientEmail(response.data["data"]["email"]);
        }
      })
    }
  }

  return (
    <div className='flex flex-col items-center'>
      <div className='flex flex-col items-center w-8/12 lg:w-4/12'>
        <p className='text-center text-lg'>
          Gentile <span className='font-bold'> {clientName} </span>a seguito
          della consulenza gratuita intercorsa Le trasmettiamo qui
          di seguito copia non vincolante della proposta contrattuale
          riguardante la ridefinizione e ristrutturazione delle Sue
          posizioni debitorie/creditorie.
        </p>
        <div className='h-8' />
        <DownloadPdfComponent pdfName='Contratto' pdf={contractUrl} />
        <div className='h-12' />
        <Link to="/istruzioni"> <GeneralButton buttonText={"PROCEDI ALLA FIRMA"} /> </Link>
      </div>
    </div>
  )
}


const DownloadPdfComponent = ({ pdfName, pdf }) => {
  return (
    <div className='flex flex-col items-center'>
      <p className='font-semibold'>Visualizza {pdfName}</p>
      <div className='h-4' />
      <a
        href={pdf}
        download={pdfName}
        target="_blank"
        rel="noreferrer"
      >
        <img src={require('../assets/pdf_icon.png')} alt='pdf-icon' width={100} height={100} />
      </a>
    </div>
  )
}