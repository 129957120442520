import React from 'react';
import ProceedButton from '../components/ProceedButton';

export default function InstructionScreen() {
  return (
    <>
      <div className='flex flex-row justify-center'>
        <div className='flex flex-col w-8/12 lg:w-4/12 items-center'>
          <p className='text-xl'>
            Al fine di poter proseguire con la firma dei contratti digitali le chiederemo i seguenti dati:
            <br></br> <br></br>
            <ol className='list-disc text-orange-600 font-bold'>
              <li> Nome </li>
              <li> Cognome </li>
              <li> Codice Fiscale </li>
              <li> Residenza </li>
              <li> Data di Nascita </li>
            </ol>
            <br></br>
            Le sarà richiesto inoltre anche il caricamente di due foto, fronte-retro, del suo <span className='text-orange-600 font-bold'>documento d’identità</span> e <span className='text-orange-600 font-bold'>codice fiscale</span>.
          </p>
          <div className='h-8' />
          <ProceedButton route={'/primo-step'} />
        </div>
      </div>

    </>
  );
}


