//import logo from './logo.svg';
// libraries import
import React from 'react'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
// custom components import
import './App.css';
import InstructionScreen from './pages/Instruction';
import FirstStepWizard from './pages/wizard/FirstStepWizard'
import ErrorPage from './error-page';
import SecondStepWizard from './pages/wizard/SecondStepWizard';
import { CustomErrorPage } from './pages/errors/presentation/CustomErrorPage';
import { ContractSigningPanel } from './pages/ContractSigningPanel';
import { Introduction } from './pages/Introduction';
import PrivateRoute from './components/PrivateRoute';
import SecondStepInstruction from './pages/wizard/SecondStepInstruction';
import ThirdStepWizard from './pages/wizard/ThirdStepWizard';

const router = createBrowserRouter([
  {
    element: <PrivateRoute />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Introduction />
      },
      {
        path: 'istruzioni',
        element: <InstructionScreen />
      },
      {
        path: 'primo-step',
        element: <FirstStepWizard />
      },
      {
        path: 'istruzioni-secondo-step',
        element: <SecondStepInstruction />
      },
      {
        path: 'secondo-step',
        element: <SecondStepWizard />
      },
      {
        path: 'terzo-step',
        element: <ThirdStepWizard />
      },
      {
        path: 'error',
        element: <CustomErrorPage />
      },
      {
        path: 'pannello-firma-contratti',
        element: <ContractSigningPanel />
      }
    ]
  },
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
