import { GeneralButton } from "../../components/GeneralButton";
import { useState } from 'react';
import { useNavigate } from "react-router-dom";

export default function SecondStepInstruction() {
  const navigate = useNavigate()
  const [selectedDocument, setSelectedDocument] = useState("altro");

  function onButtonClick() {
    if (selectedDocument === "altro") {
      navigate("/secondo-step", { state: { key: { "authorized": true }, isDocumentEnough: false } });
    } else {
      navigate("/secondo-step", { state: { key: { "authorized": true }, isDocumentEnough: true } });
    }
  }

  return (
    <div className="flex flex-col items-center">
      <div className='w-10/12 lg:w-4/12'>
        <RadioSection setSelectedDocument={setSelectedDocument} />
      </div>
      <div className='h-12' />
      <GeneralButton buttonText={"PROSEGUI"} onClick={onButtonClick} />
    </div>
  )
}

const documentTypes = [
  { id: 'carta_identita', title: 'Carta d\'identità - tessera' },
  { id: 'altro', title: 'Altro documento (patente / passaporto / carta d\'identità cartacea)' },
]

function RadioSection({ setSelectedDocument }) {
  return (
    <div>
      <label className="text-xl font-semibold text-gray-900">Documenti</label>
      <p className=" text-lg text-gray-500">Scegli il documento da caricare</p>
      <fieldset className="mt-4">
        <legend className="sr-only">Documento da caricare</legend>
        <div className="space-y-4">
          {documentTypes.map((documentType) => (
            <div key={documentType.id} className="flex items-center">
              <input
                id={documentType.id}
                name="notification-method"
                type="radio"
                defaultChecked={documentType.id === 'altro'}
                onClick={() => { setSelectedDocument(documentType.id) }}
                className="h-4 w-4 border-gray-300 text-orange-600 focus:ring-orange-600"
              />
              <label htmlFor={documentType.id} className="ml-3 block font-medium text-lg leading-6 text-gray-900">
                {documentType.title}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  )
}
