import { useLocation } from 'react-router-dom'

export const CustomErrorPage = () => {
    const location = useLocation();
    var errorMessage;

    if (location.state !== null) {
        errorMessage = location.state.errorMessage;
    } else {
        errorMessage = "Si è verificato un errore: Errore non definito."
    }

    return (
        <div className="flex flex-col items-center">
            <div className="w-10/12 lg:w-4/12 flex flex-col items-center">
                <h1 className="text-2xl font-bold text-red-600">Attenzione!</h1>
                <div className="h-4" />
                <p className="text-align-center">
                    {errorMessage}
                </p>
            </div>
        </div>
    )
}
