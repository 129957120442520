import React from "react";
import { Outlet } from "react-router";
import { useState, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import useRedirectToErrorPage from '../pages/errors/hooks/redirectToErrorPage';
import { LoadingComponent } from './LoadingComponent';
import Cookies from 'universal-cookie';

export default function PrivateRoute() {
  const clientCode = useRef("");
  const [errorMessage, setErrorMessage] = useState();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const cookies = new Cookies();
  // eslint-disable-next-line 
  const redirectToErrorPage = useRedirectToErrorPage(errorMessage);


  // Checks if there is any cookie clientCode
  useEffect(() => {
    try {
      if (typeof searchParams.get("code") !== 'undefined' && searchParams.get("code") !== null && searchParams.get("code") !== '') {
        clientCode.current = searchParams.get("code");
        cookies.set('clientCode', clientCode.current, { path: '/', sameSite: 'None', secure: true });
      } else {
        clientCode.current = cookies.get('clientCode');
      }
    } catch (err) {
      console.log(err);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // Get client data

    let body = JSON.stringify(
      {
        "key": process.env.REACT_APP_API_KEY,
        "dati": 2,
        "codice_utente": clientCode.current
      }
    )

    axios.post(`${process.env.REACT_APP_API_BASE_URL}/contratto_digitale/data.php`,
      body)
      .then((response) => {
        if (response.data["status"] !== "success") {
          setErrorMessage(`Si è verificato un errore. Rieffettuare la procedura con un nuovo link. Se l'errore persiste contattare l'assistenza. ${response.data["descrizione"]}`)
        } else {
          if (response.data["data"]["wizard_completato"] === "si")
            navigate('/pannello-firma-contratti', { state: { email: response.data["data"]["email"] } });
          else {
            navigate('/');
          }
        }
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientCode]);

  return (
    <>
      <Header />
      {clientCode !== "" ? <Outlet context={clientCode.current} /> : <LoadingComponent />}
      <div className="h-8" />
    </>
  )
}

const Header = () => {
  return (
    <>
      <div className='bg-orange-500 w-full h-24 flex flex-row justify-center items-center'>
        <img src={require("../assets/debitoo_logo.png")} className='h-12 lg:h-16 max-w-full' alt='logo'></img>
      </div>
      <div className='h-12' />
    </>
  )
}
