import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function ProceedButton({ route, clientCode, contractType }) {
    const [isShown, setIsShown] = useState(false);

    return (
        <>
            {isShown
                ? ButtonTemplate('bg-orange-500', 'text-white', setIsShown, route, clientCode, contractType)
                : ButtonTemplate('bg-transparent', 'text-orange-500', setIsShown, route, clientCode, contractType)
            }
        </>
    );
}

function ButtonTemplate(buttonBg, buttonTextColor, setIsShown, route, clientCode, contractType) {
    const navigate = useNavigate();
    return (
        <>
            <div className='h-12'></div>
            <button className={`${buttonBg} ${buttonTextColor} border-orange-500 border-2 font-bold w-64 h-16 shadow-lg`}
                onMouseEnter={() => setIsShown(true)}
                onMouseLeave={() => setIsShown(false)}
                onClick={() => navigate(route, { state: { clientCode: clientCode, contractType: contractType } })}
            >
                PROSEGUI
            </button>
            <div className='h-12'></div>
        </>
    )
}
