import { useState } from 'react';

export const GeneralButton = ({ w = 64, h=16, buttonText, onClick = () => {}}) => {
    const [isShown, setIsShown] = useState(false);

    return (
        <button className={
            isShown === true
                ?
                `bg-orange-500 text-white border-orange-500 border-2 font-bold w-${w} h-${h} shadow-lg`
                :
                `text-orange-500 border-orange-500 border-2 font-bold w-${w} h-${h} shadow-lg`
        }
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
            onClick={() => onClick()}
        >
            {buttonText}
        </button>
    );
}
