import axios from "axios";

export async function getClientDataGivenClientCode({ clientCode }) {
    let body = JSON.stringify(
        {
            "key": process.env.REACT_APP_API_KEY,
            "dati": 2,
            "codice_utente": clientCode
        }
    )

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/contratto_digitale/data.php`, body);
        return response;
    } catch (error) {
        console.error(error);
        return null;
    }
}
