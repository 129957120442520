import { useOutletContext } from 'react-router-dom'
import { useEffect, useState, useRef } from 'react';
import { GeneralButton } from '../components/GeneralButton';
import useRedirectToErrorPage from './errors/hooks/redirectToErrorPage';
import axios from "axios";
import { LoadingComponent } from '../components/LoadingComponent';

const SigningTextComponents = [
    <SignContractComponent />,
    <ContractSignedComponent />
]

export const ContractSigningPanel = () => {
    const [currentComponent, setCurrentComponent] = useState(0);
    const [text, setText] = useState('');
    const [contractUrl, setContractUrl] = useState("");
    const [isPollingActive, setIsPollingActive] = useState(false);
    const [isProcessTerminated, setIsProcessTerminated] = useState(false);
    const [header, setHeader] = useState("");
    const [errorMessage, setErrorMessage] = useState();
    const redirectToErrorPage = useRedirectToErrorPage(errorMessage);
    const tipocontratto = useRef("");
    const clientCode = useOutletContext();


    useEffect(() => {
        checkSignedContracts();
    }, [clientCode])

    function checkSignedContracts() {
        if (clientCode === '') {
            return;
        }
        let body = JSON.stringify(
            {
                "key": process.env.REACT_APP_API_KEY,
                "dati": 9,
                "codice": clientCode,
            }
        );
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/contratto_digitale/data.php`, body).then((response) => {

            if (response.data["status"] !== "success") {
                setErrorMessage("Si è verificato un errore. Non è possibile effettuare la procedura, contattare l'assistenza.")
            }
            if (response.data["data"]["contratto_firmato"] === "no" || response.data["data"]["contratto_firmato"] === "") {
                tipocontratto.current = "contratto_firmato";
                setCurrentComponent(0);
                setContractUrl(response.data["data"]["url_firma_contratto"]);
            } else if (response.data["data"]["delega_firmato"] === "no" || response.data["data"]["contratto_firmato"] === "") {
                tipocontratto.current = "delega_firmato";
                setHeader("2: FIRMA DELLA DELEGA")
                setText(`Procediamo alla firma dela delega
                attraverso la quale otterremo l’incarico
                legale per gestire le sue posizioni`);
                setContractUrl(response.data["data"]["url_firma_delega"]);
            } else if (response.data["data"]["proforma_firmato"] === "no" || response.data["data"]["contratto_firmato"] === "") {
                tipocontratto.current = "proforma_firmato";
                setHeader("3: FIRMA DELLA PROFORMA")
                setText(`Procediamo alla firma della proforma
                degli accordi di pagamento per la gestione delle posizioni`);
                setContractUrl(response.data["data"]["url_firma_proforma"]);
            } else {
                setCurrentComponent(1);
                setIsProcessTerminated(true);
            }
        });
    }

    useEffect(() => {
        if (isPollingActive === true) {
            const intervalId = setInterval(() => {
                let body = JSON.stringify(
                    {
                        "key": process.env.REACT_APP_API_KEY,
                        "dati": 9,
                        "codice": clientCode,
                    }
                );
                axios.post(`${process.env.REACT_APP_API_BASE_URL}/contratto_digitale/data.php`, body).then((response) => {

                    if (response.data["status"] !== "success") {
                        setErrorMessage("Si è verificato un errore. Non è possibile effettuare la procedura, contattare l'assistenza.")
                    }
                    if (response.data["data"][tipocontratto.current] === "si") {
                        setIsPollingActive(false);
                        checkSignedContracts();
                    }
                });
            }, 5000);
            return () => clearInterval(intervalId);
        }
    }, [isPollingActive]);

    function onButtonClick() {
        setText("Perfetto! Siamo in attesa della tua firma, non chiudere questa pagina...");
        window.open(contractUrl, "_blank", "noreferrer");
        setIsPollingActive(true);
    }

    return (
        <div className="flex flex-col items-center">
            <div className="w-10/12 lg:w-4/12 flex flex-col items-center">

                <div className="h-4" />
                {SigningTextComponents[currentComponent]}
                <div className="h-8" />
                {isPollingActive === true ?
                    <LoadingComponent /> :
                    isProcessTerminated === false ?
                        <GeneralButton buttonText={"FIRMA"} onClick={onButtonClick} /> : null}
            </div>
        </div>
    )
}

function DynamicTextComponent({ text }) {
    return (
        <div className='h-32'>
            <p className="text-align-center">
                {text}
            </p>
        </div>
    )
}

function SignContractComponent() {
    return (
        <>
            <h1 className="text-2xl font-bold text-green-600">PROCEDIAMO ALLA FIRMA DEGLI ACCORDI</h1>
            <div className="h-8" />
            <ol className="list-decimal font-bold text-xl">
                <li>CONTRATTO</li>
                <li>PROFORMA</li>
                <li>DELEGA</li>
            </ol>
            <div className="h-8" />
            <p className="text-lg text-left">
                <span className="font-bold">FAI ATTENZIONE</span>, ora sarai reindirizzato su
                <span className="font-bold"> YOUSIGN</span> per la firma del contratto.
            </p>
            <div className="h-8" />
            <p className="text-lg text-left">
                Successivamente alla firma prenderemo in carico la documentazione e sarà
                ricontattato quanto prima dal nostro ufficio amministrativo!
            </p>
        </>
    )

}

function ContractSignedComponent() {
    return (
        <>
            <h1 className="text-2xl font-bold text-green-600">FIRMA COMPLETATA</h1>
            <div className="h-8" />
            <p className="text-lg text-left">
                Perfetto, hai terminato il processo di firma! Puoi chiudere questa pagina.
            </p>
            <div className="h-8" />
            <p className="text-lg text-left font-bold">
                Sarai ricontattato nelle prossime ore dall'ufficio pre-legale per presa appuntamento
                telefonico e per iniziare l'istruttoria. <br /> Grazie!
            </p>
        </>)
}