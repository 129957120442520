import { useState, useRef, useEffect } from "react";
import { GeneralButton } from "../../components/GeneralButton";
import { LargeParagraph } from "../../components/text";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import {
  getCurrentDate,
  getCurrentDatePlusOneMonth,
} from "../../functions/getDate";
import useRedirectToErrorPage from "../errors/hooks/redirectToErrorPage";
import axios from "axios";
import { LoadingComponent } from "../../components/LoadingComponent";

export default function ThirdStepWizard() {
  const navigate = useNavigate();
  const location = useLocation();
  const [date, setDate] = useState("");
  const [hasButtonBeenClicked, setHasButtonBeenClicked] = useState(false);
  // se isDateCorrect è false allora mostro AlertWrongDate
  const [isDateCorrect, setDateCorrect] = useState(true);
  const email = useRef("");
  const [errorMessage, setErrorMessage] = useState();
  // eslint-disable-next-line
  const redirectToErrorPage = useRedirectToErrorPage(errorMessage);
  const clientCode = useOutletContext();

  useEffect(() => {
    if (location.state === null) {
      setErrorMessage(
        "Si è verificato un errore. Rieffettuare la procedura con un nuovo link. Se l'errore persiste contattare l'assistenza."
      );
    } else {
      email.current = location.state.email;
    }
  }, [location]);

  const handleInput = (value) => {
    setDate(value);
  };

  const onButtonClick = () => {
    if (!date) {
      return;
    }
    if (hasButtonBeenClicked) {
      return;
    }
    if (date < getCurrentDate() || date > getCurrentDatePlusOneMonth()) {
      setDateCorrect(false);
      return;
    }
    let body = JSON.stringify({
      key: process.env.REACT_APP_API_KEY,
      dati: 7,
      email: email.current,
      data_pagamento: date,
    });

    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/contratto_digitale/data.php`,
        body
      )
      .then((response) => {
        if (response.data.status == "error") {
          navigate("/error");
        }
        setHasButtonBeenClicked(true);
        generateContracts();
      });
  };

  const generateContracts = () => {
    let body = JSON.stringify({
      key: process.env.REACT_APP_API_KEY,
      email: email.current,
      clientCode: clientCode,
    });
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/contratto_digitale/yousign_apis/generate_yousign_urls.php`,
        body
      )
      .then((response) => {
        navigate("/pannello-firma-contratti", {
          state: { email: email.current },
        });
      });
  };

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col items-center w-10/12 lg:w-4/12">
        <img src={require("../../assets/approve_icon.jpeg")} width={100} />
        <div className="h-8" />
        {isDateCorrect === true ? null : <AlertWrongDate />}
        <h1 className="text-xl font-semibold text-orange-500">
          SCEGLI LA DATA DI PRIMO PAGAMENTO
        </h1>
        <div className="h-8" />
        <LargeParagraph
          text={`La comodità del pagamento, affinchè tu possa
                    riscontrare la nostra lavorazione,
                    scegli in tutta tranquillità la data del primo
                    pagamento entro i prossimi 30 giorni.`}
        />
        <div className="h-8" />
        <DatePicker
          key={"third-step-date-picker-01"}
          state={date}
          handleInput={handleInput}
        />
        <div className="h-8" />
        <LargeParagraph
          text={`Il giorno prescelto determinerà la scadenza delle rate mensili.`}
        />

        <div className="h-12" />
        {hasButtonBeenClicked === false ? (
          <GeneralButton buttonText={"PROSEGUI"} onClick={onButtonClick} />
        ) : (
          <LoadingComponent />
        )}
      </div>
    </div>
  );
}

function DatePicker({ key, state, handleInput }) {
  return (
    <input
      key={key}
      onChange={(e) => handleInput(e.target.value)}
      className={`border-2 p-2 
                ${state === "" ? `border-red-600` : `border-green-600`} 
                focus:outline-none w-40`}
      min={getCurrentDate()}
      max={getCurrentDatePlusOneMonth()}
      type="date"
    />
  );
}

const AlertWrongDate = () => {
  return (
    <>
      <div
        className="relative flex flex-col px-12 py-3 text-red-700 bg-red-100 border border-red-400 rounded"
        role="alert"
      >
        <strong className="font-bold">Attenzione!</strong>
        <span className="block sm:inline">
          La data inserita deve essere entro i prossimi 30 giorni.
        </span>
      </div>
      <div className="h-12"></div>
    </>
  );
};
